import React, { useMemo, useRef } from 'react'
import { FormProvider } from '@/hook-form'
import RHInput from '@/hook-form/RHInput'
import RHTextarea from '@/hook-form/RHTextarea'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { saveContact, ContactData } from '@/npc-api/contact'
import styled from 'styled-components'
import { Devices } from '@/hooks/useDevice'
import { GTM } from '@/utils/google-tag-manager'
import { GTMEventType } from '@/types/google-tag-manager.interface'
const ContactForm = () => {
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const ContactSchema = Yup.object().shape({
    name: Yup.string().max(50).required('Name is required'),
    phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
    email: Yup.string().required('Email is required').email(),
    company: Yup.string(),
    message: Yup.string().required('Message is required'),
  })

  const defaultValues = useMemo(
    () => ({
      name: '',
      phone: '',
      email: '',
      company: '',
      message: '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const methods = useForm<ContactData>({
    resolver: yupResolver(ContactSchema),
    defaultValues,
  })

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods

  const onSubmit = async (data: ContactData) => {
    GTM.sendEvent({
      event: GTMEventType.RequestContact,
    })

    await saveContact(data).then((e) =>
      reset({ name: '', phone: '', email: '', company: '', message: '' }),
    )
  }
  return (
    <ContactArea>
      <div className="container">
        <FormWrapper>
          <FormHeader>
            <ImageWrapper>
              <img src="/images/logobanner.svg" alt="Logo Image" />
            </ImageWrapper>
            <ContactTitleW>
              <ContactTitle>Get in Touch</ContactTitle>
              <ContactInfo>
                <span>Miami, Florida, United States&nbsp; </span>
                <span>
                  Phone:<ContactLink href="tel:+13057047703"> 305-704-7703</ContactLink>
                </span>
                <span>
                  Email:
                  <ContactLink href="mailto:Info@katelectronics.com">
                    Info@katelectronics.com
                  </ContactLink>
                </span>
              </ContactInfo>
            </ContactTitleW>
          </FormHeader>
          <FormProvider name="fcontrol" methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <FormGrid>
              <FormItem>
                <div>
                  <RHInput name="name" type="text" placeholder="Your Name *" required={true} />
                </div>
                <div>
                  <RHInput name="email" type="email" placeholder="Your Email *" required={true} />
                </div>
                <div>
                  <RHInput
                    name="phone"
                    type="text"
                    className="form-input"
                    placeholder="Phone Number *"
                    required={true}
                  />
                </div>
                <div>
                  <RHInput
                    name="company"
                    type="text"
                    className="form-input"
                    placeholder="Company Name"
                  />
                </div>
              </FormItem>
              <FormItem>
                <div>
                  <RHTextarea
                    className="form-input"
                    name="message"
                    placeholder="Your Message *"
                    rows={1}
                  />
                </div>
                <ButtonWrapper>
                  <SubmitButton disabled={isSubmitting} type="submit">
                    {isSubmitting ? '...Loading' : 'Send Message'}
                  </SubmitButton>
                </ButtonWrapper>
              </FormItem>
            </FormGrid>
          </FormProvider>
        </FormWrapper>
      </div>
      <TextW>
        <InfoText>
          Safety Solutions for Electrical, <br />
          Security and Fire Systems
        </InfoText>
      </TextW>
    </ContactArea>
  )
}

const ContactArea = styled.div`
  background: url('/images/contact-bg.png') center center no-repeat;
  background-size: cover;
  padding: 98px 0px 84px 0px;

  & > * {
    display: flex;
    justify-content: center;
  }
  & input,
  & input:focus,
  & textarea {
    color: #585858;
    font-size: 16px;
    font-family: Arial;
    font-weight: 400;
    line-height: 25.6px;
    word-wrap: break-word;
    padding: 12px 14px;
    background-color: #f6f6f6;
    border: 1px solid #6c6d71;
    border-radius: 4px;
    width: 100%;
  }
  & input::placeholder,
  & textarea::placeholder {
    text-transform: uppercase;
  }

  @media ${Devices.mobile.mediaQuery} {
    background: #ffffff;
    padding-bottom: 0px;
    padding-top: 0px;
  }
`
const FormWrapper = styled.div`
  max-width: 980px;
  background-color: #ffffff;
  padding: 10px 50px 40px 50px;
  width: 100%;

  @media (min-width: 769px) and (max-width: 1199px) {
    max-width: 666px;
  }
  @media ${Devices.mobile.mediaQuery} {
    max-width: none;
    padding: 0px 0px 50px 0px;
  }
  @media (max-width: 575px) {
    padding-left: 14px;
    padding-right: 14px;
`
const FormHeader = styled.div`
  background-color: #ffffff;
  display: flex;
  gap: 46px;

  & > * {
    flex: 1;
    flex-basis: 50%;
  }

  @media ${Devices.mobile.mediaQuery} {
    flex-direction: column;
    gap: 30px;
  }
`
const ImageWrapper = styled.div`
  padding: 34px;
  display: flex;
  justify-content: center;
`
const ContactTitleW = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  @media ${Devices.mobile.mediaQuery} {
    padding-left: 20%;
    padding-right: 20%;
  }
`
const ContactTitle = styled.div`
  font-family: BankGothic Md BT;
  font-size: 50px;
  font-weight: 400;
  line-height: 52px;
  letter-spacing: -0.05em;
  text-align: center;
  color: #00abec;
`

const ContactInfo = styled.div`
  & > * {
    color: #6d6e71;
    font-size: 14px;
    font-family: Arial, sans-serif;
    font-weight: 400;
    word-wrap: break-word;
    display: block;
    text-align: center;
    line-height: normal;
  }
`
const ContactLink = styled.a`
    color: #6d6e71;
    font-size: 14px;
    font-family: Arial, sans-serif;
    font-weight: 400;
    word-wrap: break-word;
}
`
const FormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  & input {
    height: 54px;
  }
  & textarea {
    height: 276px;
    max-width: 430px;
    @media (min-width: 769px) and (max-width: 1199px) {
      width: 100%;
      max-width: none;
      height: 139px;
    }
    @media ${Devices.mobile.mediaQuery} {
      max-width: none;
    }
  }

  @media (min-width: 769px) and (max-width: 1199px) {
    grid-template-columns: 1fr;
    gap: 0px;
  }
  @media ${Devices.mobile.mediaQuery} {
    grid-template-columns: 1fr;
    gap: 0px;
    margin-top: 30px;
  }
`
const FormItem = styled.div`
  & label {
    margin-bottom: 20px;
  }
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`
const SubmitButton = styled.button`
  background-color: #00abec;
  color: #ffffff;
  border: none;
  padding: 10px;
  cursor: pointer;
  width: 100%;
  height: 45px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  text-transform: capitalize;
  margin-top: 20px;

  &:hover {
    background-color: #0095cf;
  }

  &:disabled {
    background-color: #6d6e71;
    cursor: not-allowed;
  }

  @media (min-width: 769px) and (max-width: 1199px) {
    width: 430px;
  }
`
const TextW = styled.div`
  @media ${Devices.mobile.mediaQuery} {
    background: url('/images/contact-bg.png') center center no-repeat;
    background-size: cover;
    padding: 30px;
  }
`
const InfoText = styled.h2`
  margin-top: 65px;
  color: white;
  font-size: 34px;
  font-family: BankGothic Md BT;
  font-weight: 400;
  word-wrap: break-word;
  text-align: center;

  @media ${Devices.mobile.mediaQuery} {
    margin-top: 0px;
  }
`

export default ContactForm
