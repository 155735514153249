import React, { ReactNode } from "react";
// form
import { FormProvider as Form, UseFormReturn } from "react-hook-form";

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
  methods: UseFormReturn<any>;
  onSubmit?: VoidFunction;
  className?: string;
  name?: string;
};

export default function FormProvider({
  children,
  onSubmit,
  methods,
  ...other
}: Props) {
  return (
    <Form {...methods} {...other}>
      <form onSubmit={onSubmit} {...other}>
        {children}
      </form>
    </Form>
  );
}
