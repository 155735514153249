import * as React from 'react'
import Layout from '../components/Layout'
import Contact from '@/components/Contact'
import SEO from '@/components/seo'
import { useLocation } from '@reach/router'

const Ct: React.FC<{}> = () => {
  return (
    <Layout>
      <Contact />
      <SEO title="Contact Us" canonical={`${useLocation().host}`} />
    </Layout>
  )
}

export default Ct
