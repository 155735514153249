import React from 'react'
import ContactForm from './ContactForm'
//import Map from './Map'

const Contact = () => {
  return (
    <>
      <ContactForm />
      {/* <Map/> */}
    </>
  )
}

export default Contact
